import { useStoresGateway } from "@common/hooks/useStoresGateway";
import { useQuery } from "react-query";
import { gql } from "graphql-request";

export function useIndustry() {
  const { request } = useStoresGateway();

  return useQuery("industries:", async () => {
    const { industries } = await request(
      gql`query { 
        industries {
          code
        }
      }`
    )

    return industries;
  }, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })
}