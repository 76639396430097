import { useMemo } from "react";

export function convertSelectionOption(registeredIndustries: any[]) {
  return registeredIndustries.map((status: any) => Object({value: status.code, description: status.name}));
}

export default function useOrderStatus(): any[] {
  return useMemo(
    () => [   
      {
        code: "AWAITING_PROCESSING",
        name: "Aguardando Processamento"
      },
      {
        code: "PROCESSING",
        name: "Processando"
      },
      {
        code: "PROCESSED",
        name: "Processado"
      },
      {
        code: "AWAITING_ORDER_RESPONSE",
        name: "Aguardando retorno do pedido"
      },
      {
        code: "AWAITING_ORDER_INVOICE",
        name: "Aguardando a nota do pedido"
      },
      {
        code: "INVOICE_RECEIVED",
        name: "Nota recebida"
      },
      {
        code: "AWAITING_CANCELLATION_PROCESSING",
        name: "Aguardando processamento do cancelamento"
      },
      {
        code: "PROCESSING_CANCELLATION",
        name: "Cancelamento sendo processado"
      },
      {
        code: "CANCELLED",
        name: "Pedido cancelado"
      },      
    ], []);
}