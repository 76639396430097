import {
  Paper,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHeadProps,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { Fragment } from "react";
import { TableInstance } from "react-table";
import { ApiLoadingSpinner, ApiRequestError } from "@common/components/Api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const getColorRow = (index: number) => index % 2 ? "#F9F9F9" : "#FFFFFF"; 

export function Table({
  tableInstance,
  status,
  isFetching,
  error,
  className,
  renderSubComponent,
  compact,
  footer,
  tableHeadProps,
}: {
  tableInstance: TableInstance<any>;
  status?: string;
  isFetching?: boolean;
  error?: Error;
  className?: string;
  renderSubComponent?: Function;
  compact?: boolean;
  footer?: boolean;
  tableHeadProps?: TableHeadProps;
}) {
  let numberOfCols = 0;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance;
  const size = compact ? "small" : undefined;
  return (
    <div className={className}>
      <TableContainer component={Paper} >
        <MuiTable size={size} {...getTableProps()}>
          <TableHead {...tableHeadProps} >
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {renderSubComponent !== undefined ? <TableCell>&nbsp;</TableCell> : null}
                {headerGroup.headers.map((column: any) => {
                  numberOfCols++;
                  return (
                    <TableCell {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width },
                    }
                    )}>
                      <div className="whitespace-normal" style={{ height: '40px', width: column.width }}>
                        {column.render("Header")}
                      </div>
                      {column.Filter ? (
                        <div className="mt-5">{column.render("Filter")}</div>
                      ) : null}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {status === "loading" || isFetching ? (
              <TableRow>
                <TableCell colSpan={numberOfCols}>
                  <ApiLoadingSpinner />
                </TableCell>
              </TableRow>
            ) : null}
            {error ? (
              <TableRow>
                <TableCell colSpan={numberOfCols}>  
                  <ApiRequestError error={error} />
                </TableCell>
              </TableRow>
            ) : null}
            {rows.length === 0 && !(status === "loading" || isFetching) ? (
              <TableRow>
                <TableCell colSpan={numberOfCols}>
                  Não há nenhum registro para ser exibido.
                </TableCell>
              </TableRow>
            ) : null}
            {rows.map((row, index) => {
              prepareRow(row);

              return (
                <Fragment key={row.index}>
                  <TableRow {...row.getRowProps()} sx={{backgroundColor: getColorRow(index)}} >
                    {renderSubComponent !== undefined ? (
                      <TableCell style={{ width: "2em" }}>
                        <span {...(row as any).getToggleRowExpandedProps()}>
                          {(row as any).isExpanded ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </span>
                      </TableCell>
                    ) : null}

                    {row.cells.map((cell) => {
                      return (
                        <TableCell {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            width: cell.column.width,
                          },
                        })}>
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {renderSubComponent !== undefined && (row as any).isExpanded ? (
                    <TableRow>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell colSpan={numberOfCols}>
                        {renderSubComponent(row)}
                      </TableCell>
                    </TableRow>
                  ) : null}
                </Fragment>
              );
            })}
          </TableBody>

          {footerGroups && footer ? (
            <TableFooter>
              {footerGroups.map((group) => (
                <TableRow {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <TableCell {...column.getFooterProps()}>
                      {column.render("Footer")}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableFooter>
          ) : null}
        </MuiTable>
      </TableContainer>
    </div>
  );
}

