import { Typography } from "@common/components/Form/Typography";

export interface TotalWithoutResponseProps{
  total : number
}

export default function TotalWithoutResponse({ total }: TotalWithoutResponseProps) {
  return {
    card: {
      className: "mr-5 flex h-20",
      sx: { backgroundColor: "#f3f4f6", minWidth: '40px', maxWidth: '200px' },
      title: "Total Referente à todos os pedidos sem retorno",
    },

    cardHeader: {
      subheader: "Pedidos Sem Retorno:",
    },

    cardContent: {
      children: (
        <Typography variant="h4" color="red" data-testid="withoutResponse">
          {total ? total : 0}
        </Typography>
      ),
    },
  };
}
