import { Button, IntlEnum, DatePicker, Typography, Alert, SnackBar } from "@common/components";
import { useExportButton, useGridView } from "@common/context/GridView/useGridView";
import { Grid } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { format, subMonths } from 'date-fns';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { SnackbarProps } from "@mui/material";
import { useEffect, useState } from 'react';

export function setDateFilter(date: Date, isBefore: boolean = false): string {
  isBefore ? date.setHours(23, 59, 59) : date.setHours(0, 0, 0, 0);
  return format(date, "yyy-MM-dd HH:mm:ss");
}

const date = new Date();

export const minDate = () => subMonths(new Date(), 30);
export const maxDate = () => new Date(date.setHours(23, 59, 59, 999));

export type FilterTopProps = {
  begin_date: Date;
  end_date: Date;
};

const schema = yup.object({
  begin_date: yup
    .date()
    .nullable()
    .required('Campo obrigatório')
    .max(yup.ref('end_date'), 'Data final é menor Data inicial')
    .typeError('Formato de data inválido'),
  end_date: yup
    .date()
    .nullable()
    .required('Campo obrigatório')
    .min(yup.ref('begin_date'), 'Data inicial maior Data final ')
    .max(maxDate(), 'A data informada deve ser igual ou anterior a data hoje')
    .typeError('Formato de data inválido')
});

export function FilterTop(props: FilterTopProps) {

  const [open, setOpen] = useState(false);
  const maxDateSpan = process?.env?.REACT_APP_MAX_DATE_SPAN ? process?.env?.REACT_APP_MAX_DATE_SPAN : 30
  const { setDisableExportButton } = useExportButton();
  const { setGridFilters, gridFilters, items } = useGridView();

  useEffect(() => {
    if (items) {
      if (items?.pageInfo?.total <= 0) {
        setDisableExportButton(true)
      }
    }
  }, [items])
  
  const handleClose = () => {
    setOpen(false);
  };

  const alertProps = {
    severity:"error",
    sx:{width: '100%'},
    children: (
      <Typography>
        <IntlEnum name="date_filter" value="max_date_span_30_days" />
      </Typography>
    ),
  }
  
  const snackProps: SnackbarProps = {
    autoHideDuration:3000,
    anchorOrigin:{
     vertical: "top",
     horizontal: "center"
    },
    children: Alert(alertProps),
    open:open,
    onClose:handleClose
  };

  const onSubmit: SubmitHandler<FilterTopProps> = (data: FilterTopProps) => {
    let filters = gridFilters

    const oneDay = 24 * 60 * 60 * 1000
    const diff = Math.abs((new Date(data.begin_date).getTime() - new Date(data.end_date).getTime()) / oneDay);

    if (Math.floor(diff) > maxDateSpan) {
      setOpen(true);
      return
    }

    filters = filters.filter((filter) => {
      return filter.id !== 'begin_date' && filter.id !== 'end_date'
    })

    filters.push(
      { "id": "begin_date", "value": setDateFilter(data.begin_date) },
      { "id": "end_date", "value": setDateFilter(data.end_date, true) },
    )
    
    setGridFilters(filters);
    setDisableExportButton(false)
  }
    
  const { handleSubmit, control, formState: { errors, isValid } } = useForm<FilterTopProps>(
    {
      defaultValues: props,
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(schema)
    }
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SnackBar {...snackProps}/>
      <Grid
        container
        direction="row"
        justifyContent="center"
        columnSpacing={1}
        sx={{ marginBottom: 4 }}
      >
        <Grid item xs={2.0} lg={2.0}>
          <DatePicker
            label="Data Inicial"
            data-testid="begin_date"
            control={control}
            controllerName="begin_date"
            errorMessage={errors.begin_date?.message}
            minDate={minDate()}
            maxDate={maxDate()}
          />
        </Grid>
        <Grid item xs={2.0} lg={2.0}>
          <DatePicker
            label="Data Final"
            control={control}
            data-testid="end_date"
            controllerName="end_date"
            errorMessage={errors.end_date?.message}
            minDate={minDate()}
          />
        </Grid>
        <Grid 
          item 
          xs={1} 
          container 
        >
          <Grid item>
            <Button disabled={!isValid} size="medium" type="submit">BUSCAR </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
