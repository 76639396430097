import { IRoute } from "@src/routes";
import ListOrderPage from '@ca/orders/pages/ListOrdersPage';

const routes: IRoute[] = [
    {
        menu: { label: "Pedidos" },
        path: "/ca/orders",
        key: "ORDER_LIST",
        exact: true,
        component: ListOrderPage,
    }
];


export default routes;
