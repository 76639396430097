import { IntlEnum, Button } from "@common/components";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

export function ButtonExport(props: any) {
  return (
    <Button 
      type="button"
      size="medium"
      className="float-right"
      {...props}
    >
      <CloudUploadIcon className="mr-2" />
      <IntlEnum name="export_data" value="export_button"/>
    </Button>
  )
}
