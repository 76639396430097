import React from "react";
import { ButtonExport, EmailDialog } from "@shared/components";
import { useOpenDialogColumns } from "@common/context/GridView/useGridView";
import { ButtonColumnsConfig } from "@shared/components/ButtonColumnsConfig";
interface ExportComponentProps {
  serviceType: string;
}

export function ExportComponent ({
  serviceType
}: ExportComponentProps) {
  const [open, setOpen] = React.useState(false)
  const { setOpenDialogColumns } = useOpenDialogColumns()
   
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickColumnsSettingOpen = () => {
    setOpenDialogColumns(true)
  }

  return (
    <>
      <ButtonExport onClick={handleClickOpen} />
      <ButtonColumnsConfig onClick={handleClickColumnsSettingOpen} />
      <EmailDialog
        open={open}
        onHandleClick={setOpen}
        serviceType={serviceType}
      />
      
    </>
  )
}