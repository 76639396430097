import { Grid } from "@mui/material";
import { Column } from "react-table";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import {
  GridView,
  IntlEnum,
  FilteredColumn,
  IntlDateTime, 
  ColumnFilterValidated,
  ValidatedFilterProps
} from "@common/components";
import { ExportComponent, FilterTop, setDateFilter } from "@shared/components";
import { getValueEnumObject, ObjectEventEnum, CnpjValidation, ConvertIndustrySelectionOption } from "@shared/helpers";
import { Order } from "@we/orders/contracts/Order";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useOrders } from "@we/orders/hooks/useOrders";
import { EnumStatusOrder } from "@we/orders/enums/EnumStatusOrder";
import { StatusOrder } from "@we/orders/types/StatusOrder";
import useOrderStatus, { convertSelectionOption } from "@we/orders/hooks/useOrderStatus";
import { MultiSelectStatusFilterWE } from "@we/orders/components/MultiSelectStatusFilterWE/MultiSelectStatusFilterWE";
import { useIndustry } from "@we/orders/hooks/useIndustry";
import { MultiSelectIndustry } from "@we/orders/components/MultiSelectIndustry/MultiSelectIndustry";

const defaultFilterTop = {
  begin_date: new Date(),
  end_date: new Date(),
}

const defaultGridFilter: FilteredColumn[] = [
  {id: "begin_date", value: setDateFilter(defaultFilterTop.begin_date)},
  {id: "end_date", value: setDateFilter(defaultFilterTop.end_date, true)},
  {id: "status", value: ["AWAITING_INVOICE", "AWAITING_RESPONSE"]}
];

export default function GridOrder() {
  const orderStatus = useOrderStatus();
  const industries = useIndustry();

  const cnpjFilterProps:ValidatedFilterProps = {
    sx:{width: 200},
    func: CnpjValidation,
    message: <IntlEnum name="cnpj_filter" value="string_range_validation" />,
    event: ObjectEventEnum["onBlur"],
    type: "number",
    useKey:[13],
    maxValue: 14
  }

  const idValidation = ((label: JSX.Element): ValidatedFilterProps => {
    return {
      func: (value: any) => parseInt(value) <= 2147483647, 
      message: <IntlEnum name="integer_filter" value="integer_max_validation" params={{field: label}} />,
      event: ObjectEventEnum["onBlur"],
      type: "number",
      useKey:[13],
      maxValue: 10
    }
  })

  const columns: Column<Order>[] = useMemo(()=> [
    {
      Header: <IntlEnum name="we_orders_grid" value="order_code" />,
      accessor: "order_code",
      Filter: ColumnFilterValidated,
      props: idValidation(<IntlEnum name="we_orders_grid" value="order_code" />),
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="tradetools_created_at" />,
      accessor: "tradetools_created_at",
      Cell: ({ value }) => <IntlDateTime value={value} />,
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="customer_code" />,
      accessor: "customer_code",
      Filter: ColumnFilterValidated,
      props:cnpjFilterProps,
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="wholesaler_branch_code" />,
      accessor: "wholesaler_branch_code",
      Filter: ColumnFilterValidated,
      props:cnpjFilterProps,
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="status" />,
      accessor: "status",
      Cell: ({ value }) => getValueEnumObject<StatusOrder>(EnumStatusOrder, value),
      Filter: MultiSelectStatusFilterWE,
      options: convertSelectionOption(orderStatus),
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="industry_code" />,
      accessor: "industry_code",
      Filter: MultiSelectIndustry,
      options: ConvertIndustrySelectionOption(industries.data),
      Cell: ({ value }) => value,
    },
    {
      Header: "",
      width: '10px',
      id: "icon",
      Cell: ({ row }: any) => (
        <>
          <Link to={`/we/OrderDetails/${row.original.order_code}/${row.original.industry_code}`} style={{ width: '10px' }}>
            <AddCircleIcon sx={{fill: "green"}}/>
          </Link>
        </>
      ),
    },
  ],[industries]);

  const RenderGridOrder= () => {
    return (
      <GridView<Order>
          columns={columns} 
          hook={useOrders}
          defaultFilters={defaultGridFilter}
          paginated
          tableIdentifier="WE"
      />
    )
  }

  return (
    <>
      <FilterTop {...defaultFilterTop} />
      <Grid style={{marginTop: -70 + 'px'}}>
        <ExportComponent serviceType="WE" />
      </Grid>
      <RenderGridOrder />
    </>
  )
}