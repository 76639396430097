import { Pagination as MuiPagination, PaginationProps  as MuiPaginationProps } from "@mui/material";
import { Grid } from "@mui/material";

export interface PaginationProps extends MuiPaginationProps {
  setPage?: Function;
}

export const Pagination = (props: PaginationProps) => {
  const {setPage, ...newProps } = props;

  const handleChange = (event: any, value: number) => {
    if (setPage){
      setPage(value);
    }
  }   

  return (
    <>
      <Grid container justifyContent="center" padding={1}>
        <MuiPagination color="primary"  {...newProps} onChange={handleChange} />
      </Grid>
    </>
  );
};
