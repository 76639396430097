import ROUTES, { RenderRoutes } from "./routes";
import { AuthProvider, AuthService } from "react-oauth2-pkce";
import LoginPage from "./modules/common/pages/LoginPage";
import { Layout } from "./modules/common/components/Layout";
import { IntlProvider } from "react-intl";
import messagesInPortuguese from "./lang/pt-BR.json";
import PermissionProvider from "./modules/permission/PermissionProvider";
import fetchPermission from "./modules/permission/fetchPermission";
import { jwtToUser } from "./modules/common/hooks";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { theme } from "./theme";
import TagManager from 'react-gtm-module'

const authService = new AuthService({
  clientId:
    process?.env?.REACT_APP_SSO_OAUTH_CLIENT_ID ||
    window?.environment?.REACT_APP_SSO_OAUTH_CLIENT_ID ||
    "0",
  location: window.location,
  provider: `${
    process?.env?.REACT_APP_SSO_URL || window?.environment?.REACT_APP_SSO_URL
  }/oauth2`,
  redirectUri: window.location.origin,
  scopes: (
    process?.env?.REACT_APP_SSO_OAUTH_SCOPES ||
    window?.environment?.REACT_APP_SSO_OAUTH_SCOPES ||
    "name,profiles"
  ).split(","),
});

const tagManagerArgs = {
  gtmId: process?.env?.REACT_APP_GOOGLE_TAG_MANAGER_CODE || window?.environment?.REACT_APP_GOOGLE_TAG_MANAGER_CODE || ''
}

TagManager.initialize(tagManagerArgs)

function App() {
  let user = undefined;
  try {
    if (authService.isAuthenticated()) {
      user = jwtToUser(authService.getAuthTokens()?.access_token);
    }
  } catch (error) {
    console.log(error);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <IntlProvider
        messages={messagesInPortuguese}
        locale="pt-BR"
        defaultLocale="pt-BR"
      >
        <AuthProvider authService={authService}>
          {user ? (
            <PermissionProvider fetchPermission={fetchPermission(user)}>
              <Layout>
                <RenderRoutes routes={ROUTES} />
              </Layout>
            </PermissionProvider>
          ) : (
            <LoginPage />
          )}
        </AuthProvider>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
