import { IntlEnum, Title } from '@common/components';
import GridOrder from '@ca/orders/components/GridOrder/GridOrder';
import StatusTotal from '@ca/orders/components/StatusTotal/StatusTotal';
import { GridViewProvider } from '@common/context/GridView/useGridView';


export default function ListOrder() {
    return (
        <GridViewProvider>
            <Title>
                <IntlEnum name="orders" value="orders"/>
            </Title>
            <StatusTotal />
            <GridOrder />
        </GridViewProvider>
    )
}
