// import { TextField } from "@common/components/Form";
import { Typography } from "@common/components/Form/Typography";
import { Alert } from '@common/components/Form/Alert';
import { SnackbarProps } from "@mui/material";
import {SnackBar} from '@common/components/Form/SnackBar';
import { useState } from 'react';
import { TextFieldProps, TextField } from "@mui/material";
import { ObjectEventEnum } from "@shared/helpers/ObjectEventEnum";
import { useAsyncDebounce } from "react-table";
export interface ValidatedFilterProps
{
  sx?: any;
  func?: any;
  message?: any;
  event?:ObjectEventEnum;
  type?: String;
  useKey?: Array<number>;
  maxValue?: number
}

export function ColumnFilterValidated({
  column: {setFilter, props},
}: any) {

  const handleClose = () => {
    setOpen(false);
  };

  const [open, setOpen] = useState(false);
  const text = props && props.message ? props.message : ""
  const filterEvent: ObjectEventEnum = props && props.event ? props.event : ObjectEventEnum["onChange"];

  const alertProps = {
    severity:"error",
    sx:{width: '100%'},
    children: (
      <Typography>
        {text}
      </Typography>
    ),
  }

  const snackProps: SnackbarProps = {
    autoHideDuration:3000,
    anchorOrigin:{
     vertical: "top",
     horizontal: "center"
    },
    children: Alert(alertProps),
    open:open,
    onClose:handleClose
  };

  const textFieldProps: TextFieldProps = {};

  const debounceValue = useAsyncDebounce(value => {
    setFilter(value || undefined)
  }, 500)

  const filterValidation: any = (e: any) => {
    if(e.type === "keypress" && props.useKey.indexOf(e.charCode) === -1)
      return;

    const valid = props && props.func ? props.func(e.target.value) : true 
    if(valid || e.target.value === '') {
      debounceValue(e.target.value)
      return;
    }

    if (e.target.value) {
      setOpen(true);
    }
  }

  textFieldProps.size="small";
  textFieldProps.type= props && props.type ? props.type : "text";
  textFieldProps.sx= props && props.sx ? props.sx : null

  textFieldProps[filterEvent] = filterValidation
  textFieldProps.onKeyPress = props && props.useKey ? filterValidation : () => {};
  // textFieldProps.onChange = props ? filterValidation : () => {};
  
  textFieldProps.placeholder="Filtrar"

  if (props?.maxValue) {
    textFieldProps.onInput = (e: any) => {
      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, props.maxValue);
    }
  }
  
  return (
    <>
      <SnackBar {...snackProps}/>
      <TextField 
        {...textFieldProps}
       />
    </>
  );
}
