/* eslint-disable react/style-prop-object */
import { FormattedNumber } from "react-intl";

export function IntlPercentage({
  value,
}: {
  value: number | bigint | undefined | null;
}) {
  if (value === undefined || value === null) {
    return null;
  }
  return (
    <span>
      <FormattedNumber value={value} style="decimal" />%
    </span>
  );
}
