import { useStoresGateway } from "@common/hooks";
import { gql } from "graphql-request";
import { useQuery } from "react-query";
import { format } from "date-fns";

export interface OrdersArgsFilterInput {
  begin_date?: string,
  end_date?: string,
}

export function setFilters(begin_date: string | undefined, end_date: string | undefined) {
  const filter: OrdersArgsFilterInput = {
    begin_date,
    end_date
  };

  filter.begin_date = filter.begin_date !== undefined ? filter.begin_date : format(new Date(), "yyyy-MM-dd");
  filter.end_date = filter.end_date !== undefined ? filter.end_date : format(new Date(), "yyyy-MM-dd")
  
  return filter;
}

export function useStatusTotal(filters: OrdersArgsFilterInput) {
  const { request } = useStoresGateway();

  const filter = { filter: setFilters(filters.begin_date, filters.end_date) }

  return useQuery("groupedOrderTotalizer:" + JSON.stringify(filter), async () => {
    const { groupedOrderTotalizer } = await request(
      gql`
        query groupedOrderTotalizer ($filter: groupedOrderTotalizerFilter) {
          groupedOrderTotalizer (filter: $filter) {
            totalizerWithoutInvoice
            totalizerWithoutAnswer
          }
        }`, filter
    );

    return groupedOrderTotalizer;
  }, {
    refetchOnWindowFocus: false
  });
}
