import { useStoresGateway } from "@common/hooks/useStoresGateway";
import { useQuery } from "react-query";
import { gql } from "graphql-request";
import { Order } from "@ca/orders/contracts/Order";
import { FilteredColumn } from "@common/components";
import { convertResponseCursorPaginated } from "@shared/helpers";
import { format, subDays } from "date-fns";
import { EnumSortField } from "@ca/orders/enums/EnumSortfield";
import { SortFieldType } from "@ca/orders/types/SortField";

export interface OrdersArgsFilterInput {
  begin_date?: string,
  end_date?: string,
  client_identification?: string;
  wholesaler_branch_code?: string;
  status?: string | Array<string>;
  id?: number;
  client_code?: string;
  codes?: Array<string>;   
}

export interface SortFields {
  field: SortFieldType,
  direction: 'ASC' | 'DESC'
}

interface UseOrdersOptions {
  page?: number,
  limit?: number,
  perPage?: number,
  filter?: OrdersArgsFilterInput;
  gridFilters?: FilteredColumn[];
  sort?: SortFields;
  send_to?: string;
}

export function setDefaultOrderFilter() {
  const sort: SortFields = {
      field: EnumSortField.CREATED_AT,
      direction: 'DESC'
  }

  return sort;
}

export function setFilters(options: UseOrdersOptions) {
  const filter: OrdersArgsFilterInput =  options.filter || {};

  filter.begin_date = filter.begin_date !== undefined ? filter.begin_date : format(subDays(new Date(), 3), "yyyy-MM-dd");
  filter.end_date = filter.end_date !== undefined ? filter.end_date : format(new Date(), "yyyy-MM-dd")

  return filter;
}

export function useOrders( options: UseOrdersOptions | undefined = {}){

  const filter: OrdersArgsFilterInput = {};

  const sort: SortFields = setDefaultOrderFilter();

  options.page = 1;
  options.limit = process.env.REACT_APP_LIMIT_PER_PAGE ? Number(process.env.REACT_APP_LIMIT_PER_PAGE) : 10;
  options.sort = sort;
  options.filter = filter;

  if (options.gridFilters) {
      options.gridFilters.forEach((filteredColumn: FilteredColumn) => {

        if (filteredColumn.id === 'begin_date') {
          filter.begin_date = format(new Date(filteredColumn.value), "yyyy-MM-dd")
        } else if (filteredColumn.id === 'end_date') {
          filter.end_date = format(new Date(filteredColumn.value), "yyyy-MM-dd")
        } else if (filteredColumn.id === 'currentPage') {
          options.page = filteredColumn.value;
        } else if (filteredColumn.id === 'client_identification') {
          filter.client_identification = filteredColumn.value;
        } else if (filteredColumn.id === 'client_code') {
          filter.client_code = filteredColumn.value;
        } else if (filteredColumn.id === 'status') {
          filter.status = filteredColumn.value;

          // set default filters
          if (filteredColumn.value.length <= 0) {
            filter.status = ["AWAITING_PROCESSING", "AWAITING_ORDER_RESPONSE", "AWAITING_ORDER_INVOICE"];
          }
        } else if (filteredColumn.id === 'id') {
          filter.id = Number(filteredColumn.value);
        } else if (filteredColumn.id === 'industry_codes') {
          filter.codes = filteredColumn.value;
        } else if (filteredColumn.id === 'wholesaler_branch') {
          filter.wholesaler_branch_code = filteredColumn.value;
        }
      })

      options.filter = filter;
  }
  
  const { request } = useStoresGateway();

  return useQuery("groupedOrders:" + JSON.stringify(options), async () => {
      const { groupedOrders } = await request(
        gql`
        query groupedOrders(
            $filter: GroupedOrderFilterType,
            $page: Int!,
            $limit: Int!,
            $sort: GroupedOrdersSortType,
            $send_to: String
        ) {
              groupedOrders(
                  page: $page,
                  limit: $limit
                  filter: $filter
                  sort: [$sort]
                  send_to: $send_to
                  cached: true
              ) {
                  total
                  from
                  to
                  current_page
                  per_page
                  last_page
                  has_more_pages
                  data {
                      id
                      created_at
                      client_identification
                      wholesaler_branch {
                        code
                      }
                      industry_codes: products {
                        industry_abbreviation
                      }
                      status
                      client_code
                  }
              }
        }`, options
      );

      return convertResponseCursorPaginated<Order>(groupedOrders);
  }, {
    refetchOnWindowFocus: false
  });
}
