import { IntlEnum, Typography } from "@common/components";
import { GridviewData } from "@shared/components";
import { SubOrderDetail } from "@we/orderDetails/contracts/SubOrderDetail";
import { Column } from "react-table";

const columns: Column<SubOrderDetail>[] = [
  {
    Header: <IntlEnum name="we_orders_grid" value="industry" />,
    accessor: "industry_code",
  },
  {
    Header: <IntlEnum name="we_orders_grid" value="ean" />,
    accessor: "ean",
  },
  {
    Header: <IntlEnum name="we_orders_grid" value="amount" />,
    accessor: "quantity",
  },
  {
    Header: <IntlEnum name="we_orders_grid" value="discount" />,
    accessor: "discount",
  },
  {
    Header: <IntlEnum name="we_orders_grid" value="reimbursement" />,
    accessor: "reimbursement_value",
  },
  {
    Header: <IntlEnum name="we_orders_grid" value="invoice" />,
    accessor: "danfe",
  },
  {
    Header: <IntlEnum name="we_orders_grid" value="product_status" />,
    accessor: "status",
  },
];

interface GridViewProps<T> {
  data: T[],
}

export default function GridSubOrderDetail<SubOrderDetail>({
  data
}: GridViewProps<SubOrderDetail>) {
  return (
    <>
      <Typography variant="h6" gutterBottom component="div">
        <IntlEnum name="orders" value="products"/>
      </Typography>

      <GridviewData<SubOrderDetail>
        columns={columns}
        data={data}
      />
    </>
  )
}