import { InlineSpinner } from "@common/components/InlineSpinner";

export function ApiLoadingSpinner({ message }: { message?: string }) {
  message = message || "Carregando informações...";
  return (
    <div className="py-2 text-sm text-gray-500">
      <InlineSpinner>{message}</InlineSpinner>
    </div>
  );
}
