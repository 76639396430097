import {Card} from '@common/components/Form/Card';
import {CardContent} from '@common/components/Form/CardContent';
import {CardHeader} from '@common/components/Form/CardHeader';
import { CardProps as cardProps, 
CardHeaderProps,
CardContentProps
} from "@mui/material"

export interface CardRenderProps {
    cardHeader?: CardHeaderProps
    card?: cardProps
    cardContent?: CardContentProps
}

export const CardRender = (props: CardRenderProps) => {
    return<Card {...props.card} >
            <CardHeader {...props.cardHeader}></CardHeader>
            <CardContent {...props.cardContent}></CardContent>
        </Card>
        
  };
