import { Typography } from "@common/components/Form/Typography";

export interface TotalWithoutInvoiceProps{
  total : number
}

export default function TotalWithoutInvoice({ total }: TotalWithoutInvoiceProps) {
  return {
      card: {
        className: "mr-5 flex h-20",
        sx: { backgroundColor: "#f3f4f6", minWidth: '40px', maxWidth: '200px' },
        title: "Total Referente à todos os pedidos sem nota",
      },
  
      cardHeader: {
        subheader: "Pedidos Sem Nota:",
      },
  
      cardContent: {
        children: (
          <Typography variant="h4" color="red" data-testid="withoutInvoice">
            {total ? total : 0}
          </Typography>
        ),
      },
    };
}
