import { useStoresGateway } from "@common/hooks/useStoresGateway";
import { useQuery } from "react-query";
import { gql } from "graphql-request";
import { Order } from "@we/orders/contracts/Order";
import { convertResponseCursorPaginated } from "@shared/helpers";
import { format, subDays } from "date-fns";
import { FilteredColumn } from "@common/components";

export interface industryFilterType {
  code: [String]
}
export interface OrdersArgsFilterInput {
  begin_date?: string,
  end_date?: string,
  industries?: industryFilterType
}
interface UseOrdersOptions {
  page?: number,
  limit?: string,
  perPage?: number,
  filter?: OrdersArgsFilterInput;
  gridFilters?: FilteredColumn[];
  id?: number;
  order_code?: number;
  customer_code?: string;
  wholesaler_branch_code?: string;
  status?: string | Array<string>;
  imported?: boolean;
  send_to?: string;
}

export function setFilters(options: UseOrdersOptions) {
  const filter: OrdersArgsFilterInput =  options.filter || {};

  filter.begin_date = filter.begin_date !== undefined ? filter.begin_date : format(subDays(new Date(), 3), "yyyy-MM-dd");
  filter.end_date = filter.end_date !== undefined ? filter.end_date : format(new Date(), "yyyy-MM-dd")
  
  return filter;
}

export function useOrders(options: UseOrdersOptions | undefined = {}) {
  const filter: OrdersArgsFilterInput =  {};

  options.page = options.page ? options.page : 1;
  options.limit = process.env.REACT_APP_LIMIT_PER_PAGE ? process.env.REACT_APP_LIMIT_PER_PAGE : '10';
  options.filter = filter;
  options.imported = true;

  if (options.gridFilters) {

      options.gridFilters.forEach((filteredColumn: FilteredColumn) => {
        
        if (filteredColumn.id === 'begin_date') {
          filter.begin_date = format(new Date(filteredColumn.value), "yyyy-MM-dd")
        } else if (filteredColumn.id === 'end_date') {
          filter.end_date = format(new Date(filteredColumn.value), "yyyy-MM-dd")
        } else if (filteredColumn.id === 'industry_code') {
          filter.industries = filteredColumn.value.map((item: string) => {
            return {
              code: item
            }
          })
        } else if (filteredColumn.id === 'order_code'){
          options.order_code = filteredColumn.value;
        } else if (filteredColumn.id === 'customer_code'){
          options.customer_code = filteredColumn.value;
        } else if (filteredColumn.id === 'wholesaler_branch_code'){
          options.wholesaler_branch_code = filteredColumn.value;
        } else if (filteredColumn.id === 'status') {
          options.status = filteredColumn.value;
          options.imported = false;
          const isOrderNotImported = filteredColumn.value.find((value: any) => { return value === 'ORDER_NOT_IMPORTED'});

          if (filteredColumn.value.length > 0 && !isOrderNotImported) {
            options.imported = true;
          }

          // set default filters
          if (filteredColumn.value.length <= 0) {
            options.imported = true;
            options.status = ['AWAITING_INVOICE', 'AWAITING_RESPONSE'];
          }

        } else if (filteredColumn.id === 'currentPage') {
          options.page = filteredColumn.value;
        } else if (filteredColumn.id === 'send_to' && filteredColumn.value !== "") {
          options.send_to = filteredColumn.value;
        }
      })
      options.filter = filter;
  }

  const { request } = useStoresGateway();

  return useQuery("orders:" + JSON.stringify(options), async () => {
    const { orders } = await request(
      gql`
        query orders (
            $filter: OrderFilter,
            $id: Int, 
            $order_code: Int,
            $customer_code: String,
            $wholesaler_branch_code: String,
            $imported: Boolean,
            $status: [OrderStatusEnum],
            $page: Int!,
            $limit: Int!,
            $send_to: String
          )
          {
          orders (
            filter: $filter, 
            id: $id, 
            order_code: $order_code,
            customer_code: $customer_code,
            wholesaler_branch_code: $wholesaler_branch_code,
            imported: $imported,
            status: $status,
            current_page: $page,
            per_page: $limit,
            send_to: $send_to,
            cached: true
          ) 
            {
            total
            from
            to
            current_page
            per_page
            last_page
            has_more_pages
            data {
              id
              order_code
              tradetools_created_at
              customer_code
              industry_code
              products { 
                ean
                discount_percentage
              }
              wholesaler_branch_code
              status
              invoices {
                id
                reimbursement_values {
                  value
                  formula
                }
              }
            }
          }
        }`, options
      );

    return convertResponseCursorPaginated<Order>(orders);
  }, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
