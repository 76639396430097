import { CursorPaginated, OrderAPI } from "@types";

export function convertResponseCursorPaginated<T>(response: OrderAPI<T>): CursorPaginated<T> {
    return {
      pageInfo: {
        hasPreviousPage: response.current_page > 1,
        hasNextPage: response.has_more_pages,
        currentPage: response.current_page,
        lastPage: response.last_page,
        perPage: response.per_page,
        fromCursor: response.from,
        toCursor: response.to,
        total: response.total
      },
      edges: response.data
    }
  }
  