export function Title({
  children,
  level,
  marginBottom,
}: {
  children: any;
  level?: number;
  marginBottom?: 0 | 1 | 2 | 4 | 8 | 16;
}) {
  if (marginBottom === undefined) {
    marginBottom = 4;
  }
  let margin = ` mb-` + marginBottom;
  let sizeClassName = "text-3xl";
  if (level === 2) {
    sizeClassName = "text-2xl";
  }
  if (level === 3) {
    sizeClassName = "text-xl";
  }
  if (marginBottom === 0) {
    margin = "";
  }
  return (
    <h1 className={sizeClassName + " " + margin + " font-bold text-gray-600"}>
      {children}
    </h1>
  );
}
