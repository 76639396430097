import { Title } from "./Title";

export function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div role="alert">
      <Title>Oops! Ocorreu algo errado!</Title>
      <pre style={{ whiteSpace: "break-spaces" }}>{error.message}</pre>
      {resetErrorBoundary ? (
        <button onClick={resetErrorBoundary}>Try again</button>
      ) : null}
    </div>
  );
}
