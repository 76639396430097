import { Column } from "react-table";
import { useCallback, } from "react";
import { IntlEnum, ApiLoadingSpinner, IntlDateTime } from "@common/components";
import { getValueEnumObject } from "@shared/helpers";
import { GridviewData } from "@shared/components";
import { useOrderDetails } from "@we/orderDetails/hooks/useOrderDetails";
import { Order } from "@we/orders/contracts/Order";
import { EnumStatusOrder } from "@we/orders/enums/EnumStatusOrder";
import { StatusOrder } from "@we/orders/types/StatusOrder";
import { useJwtUser } from '@common/hooks';
import GridSubOrderDetail from "../GridSubOrderDetail/GridSubOrderDetail";
import { SubOrderDetail } from "@we/orderDetails/contracts/SubOrderDetail";
import { ProductInvoice } from "@we/orderDetails/contracts/ProductInvoice";
import { ReimbursementValue } from "@we/orderDetails/contracts/ReimbursementValue";

interface ListOrderDetailsProps {
  order_code: string;
  industry_code: string;
}

export default function ListOrderDetails({ order_code, industry_code }: ListOrderDetailsProps) {
  const order =  useOrderDetails({ order_code, industry_code });
  const { user } = useJwtUser();
  const columns: Column<Order>[] = [
    {
      Header: <IntlEnum name="we_orders_grid" value="order_code" />,
      accessor: "order_code",
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="customer_code" />,
      accessor: "customer_code",
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="wholesaler_branch_code" />,
      id: 'wholesaler_branch_code',
      accessor: "wholesaler_branch",
      Cell: ({ value }) => value?.code ?? null,
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="tradetools_created_at" />,
      accessor: "tradetools_created_at",
      Cell: ({ value }) => <IntlDateTime value={value} />,
    },
    {
      Header: <IntlEnum name="we_orders_grid" value="status" />,
      accessor: "status",
      Cell: ({ value }) => getValueEnumObject<StatusOrder>(EnumStatusOrder, value),
    },
  ];

  if (user?.hasSsoRole('master')) {
    columns.splice(2, 0, {
      id: 'wholesaler_branch_name',
      accessor: 'wholesaler_branch',
      Header: <IntlEnum name="we_orders_grid" value="wholesaler" />,
      Cell: ({ value }) => value?.name ?? null,
    })
    columns.join()
  }
  
  const renderRowSubComponent = useCallback(
    (row) => {
      let data: SubOrderDetail[] = []
      const products = row.original.products
      if (products) {
        data = products.map((product: any) => {
          const invoices = row.original.invoices;

          const productInvoice: ProductInvoice | undefined = invoices?.find((invoice: any) => {
            return invoice?.products.find((productInvoice: any) => {
              return productInvoice?.ean === product?.ean
            })
          })

          let productReimbursements: ReimbursementValue | undefined = {
            value: 0,
            ean: '',
          }

          if (productInvoice) {
            productReimbursements = productInvoice.reimbursement_values.find((reimbursementValue: any) => {
              return reimbursementValue.ean === product.ean
            })
          }
          
          return {
            industry_code: product.industry_code ? product.industry_code : row.original.industry_code,
            ean: product.ean,
            quantity: product.amount,
            discount: product.discount_percentage,
            reimbursement_value: productReimbursements?.value,
            danfe: productInvoice?.danfe_key ? productInvoice?.danfe_key : '-',
            status: productInvoice?.importation_outcome ? productInvoice?.importation_outcome : '-'
          } as SubOrderDetail
        })
      }
      return <GridSubOrderDetail data={data} />
    }, []
  );
  
  const RenderGridViewData = () => {
    return (
      <GridviewData<Order>
        columns={columns}
        data={order.data?.edges}
        renderRowSubComponent={renderRowSubComponent}
      />
    );
  }

  if (order.isLoading) {
    return (
      <div className="w-56 ml-2">
        <ApiLoadingSpinner />
      </div>
    );
  }

  return (
    <RenderGridViewData />
  )
}
