import { MenuItem } from "@mui/material";
import { TextField, TextFieldProps } from "./TextField";

export const SelectFieldOption = (props: any) => {
  return <MenuItem {...props} />;
};

export const SelectField = (props: TextFieldProps) => {
  return <TextField {...props} select />;
};
