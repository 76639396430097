import { BreadCrumb } from "@common/components";
import ListOrderDetails from "@we/orderDetails/components/ListOrderDetails/ListOrderDetails";
import { useParams } from "react-router-dom";

interface OrdersDetailsParams {
  order_code: string;
  industry_code: string;
}

export default function OrdersDetails() {

  let {order_code, industry_code}  = useParams<OrdersDetailsParams>()

  return (
    <>
      <BreadCrumb items={ [ { path: "/we/orders", label: "Pedidos" }, { label: "Detalhes do Pedido"} ] }/>
      <ListOrderDetails order_code={order_code} industry_code={industry_code} />
    </>
  );
}
