import {
  CardRender,
  CardRenderProps,
} from "@common/components/Form/CardRender";
import { useStatusTotal } from "@we/orders/hooks/useStatusTotal";
import TotalWithoutResponse from "@we/orders/components/StatusTotal/TotalWithoutResponse";
import TotalWithoutInvoice from "@we/orders/components/StatusTotal/TotalWithoutInvoice";
import { useGridView } from "@common/context/GridView/useGridView";
import { format } from "date-fns";
import { useEffect, useState } from "react";

interface FilterDates {
  begin_date: string,
  end_date: string
}

export default function StatusTotal() {
  let defaultFilterTop: FilterDates = {
    begin_date: format(new Date(), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
  }

  const [ filterTop, setFilterTop ] = useState<FilterDates>(defaultFilterTop);

  const { gridFilters } = useGridView()
  
  let total = useStatusTotal(filterTop);

  useEffect(() => {
    if (gridFilters !== undefined && gridFilters.length > 0) {
      let begin_date = gridFilters.find((gridFilter: any) => { return gridFilter.id === 'begin_date'})?.value
      let end_date = gridFilters.find((gridFilter: any) => { return gridFilter.id === 'end_date'})?.value

      if (begin_date !== undefined && end_date !== undefined) {
        begin_date = format(new Date(begin_date), 'yyyy-MM-dd');
        end_date = format(new Date(end_date), 'yyyy-MM-dd');
        defaultFilterTop = {
          begin_date,
          end_date
        }
  
        setFilterTop(defaultFilterTop)
      }
    }
  }, [gridFilters]);

  const propsInvoice: CardRenderProps = TotalWithoutInvoice({total : total.data ? total.data.totalizerWithoutInvoice : 0});
  const propsResponse: CardRenderProps = TotalWithoutResponse({total : total.data ? total.data.totalizerWithoutResponse : 0});
  
  return (
    <div className="float-right md:flex mb-5">
      <CardRender {...propsInvoice} />

      <CardRender {...propsResponse} />
    </div>
  );
}
