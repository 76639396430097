import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IntlEnum, Button, TextField } from "@common/components";
import { useExportButton, useTableFilters } from "@common/context/GridView/useGridView";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { SnackbarProps } from "@mui/material";
import { Alert } from "@common/components/Form/Alert";
import { useState } from "react";
import { SnackBar } from "@common/components/Form/SnackBar";
import { grey } from "@mui/material/colors";
import { useStoresGateway } from "@common/hooks/useStoresGateway";
import { SendExportCA } from "@ca/orders/hooks/useExportOrders";
import { SendExportWE } from "@we/orders/hooks/useExportOrders";

interface EmailDialogProps {
  open: boolean;
  onHandleClick: Function;
  serviceType: string;
}

export type EmailDialogTypeProps = {
  email: string;
};

const schema = yup.object({
  email: yup
    .string()
    .email('Este campo deve conter um e-mail válido.')
    .required('Este campo é obrigatório')
});

export function EmailDialog ({
    open,
    onHandleClick,
    serviceType
}: EmailDialogProps) {
  const [openSnack, setOpenSnack] = useState(false)
  const { tableFilters } = useTableFilters();
  const { setDisableExportButton } = useExportButton();
  const [alertProps, setAlertProps] = useState({});
  const { request } = useStoresGateway();

  const closeSnackbar = () => {
    setOpenSnack(false)
  }

  const snackProps: SnackbarProps = {
    autoHideDuration:5000,
    anchorOrigin:{
     vertical: "top",
     horizontal: "center"
    },
    children: Alert(alertProps),
    open:openSnack,
    onClose:closeSnackbar
  };
  
  const handleClose = () => {
    onHandleClick(false);
  };

  const { handleSubmit, control, formState: { errors, isValid } } = useForm<EmailDialogTypeProps>(
    {
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {
        email: ""
      }
    }
  );

  const OnSubmit: SubmitHandler<EmailDialogTypeProps> = async (data: EmailDialogTypeProps) => {
    let response = null;

    switch (serviceType) {
      case 'CA':
        response = await SendExportCA(data.email, tableFilters, request)
        break;
      case 'WE':
        response = await SendExportWE(data.email, tableFilters, request)
        break;
      default:
        return
    }

    if (response.status === 'FAILED') {
      setAlertProps({
        severity: 'error',
        sx:{width: '100%'},
        children: response.message,
      })
    } else {
      setAlertProps({
        severity: 'success',
        sx:{width: '100%'},
        children: response.message,
      })
    }
    
    setOpenSnack(true)
    setDisableExportButton(true)
    handleClose()
  }

  const greyColor = grey[400]

  return (
    <>
      <SnackBar {...snackProps}/>
      <Dialog open={open} onClose={handleClose} fullWidth={true} >
        <form onSubmit={handleSubmit(OnSubmit)}>
          <DialogContent>
              <DialogContentText className="text-center py-5 px-5">
                  <IntlEnum name="export_data" value="dialog_message"/>
              </DialogContentText>
              <TextField
                control={control}
                controllerName="email"
                name="email"
                errorMessage={errors.email?.message}
                label={<IntlEnum name="export_data" value="email_placeholder"/>}
                fullWidth
                variant="standard"
                autoFocus
                margin="dense"
              />
          </DialogContent>
          <DialogActions style={{justifyContent: 'center'}} className="mb-5">
              <Button type="submit" disabled={!isValid}>
                  <IntlEnum name="export_data" value="prepare_export_button"/>
              </Button>
              <Button onClick={handleClose} sx={{ backgroundColor: greyColor }}>
                  <IntlEnum name="export_data" value="prepare_export_cancel"/>
              </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}