import { JwtUser } from "../common/hooks";
import { Permission } from "./Types";

const fetchPermission =
  (user: JwtUser) =>
  async (permission: Permission): Promise<boolean> => {
    let allowed = false;

    if (
      user.hasAnyOfTheseSsoRoles("master", "support", "pharma_benefit_manager")
    ) {
      // read only
      if (["list", "show"].includes(permission.split(".")[0])) {
        allowed = true;
      }

      // create, update and delete
      if (user.hasAnyOfTheseSsoRoles("master", "pharma_benefit_manager")) {
        allowed = true;
      }
    }

    return Promise.resolve(allowed);
  };

export default fetchPermission;
