import { IconButton } from "@common/components";
import Settings from '@mui/icons-material/Settings';

export function ButtonColumnsConfig(props: any) {
  return (
    <IconButton 
      type="button"
      size="medium"
      className="float-right"
      {...props}
      color="primary"
    >
      <Settings />
    </IconButton>
  )
}
