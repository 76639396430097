import { format } from "date-fns";
import { gql } from "graphql-request";

export interface OrdersArgsFilterInput {
  begin_date?: string,
  end_date?: string,
  client_identification?: string;
  status?: string | Array<string>;
  id?: number;
}

const hookColumns: { column: string, value: string }[] = [
  {
    column: 'id',
    value: 'PEDIDO'
  },
  {
    column: 'created_at',
    value: 'DATA_DO_PEDIDO'
  },
  {
    column: 'client_identification',
    value: 'CNPJ_PDV'
  },
  {
    column: 'client_code',
    value: 'CODIGO_DO_CLIENTE'
  },
  {
    column: 'wholesaler_branch',
    value: 'CNPJ_FILIAL_DISTRIBUIDOR'
  },
  {
    column: 'status',
    value: 'STATUS_DO_PEDIDO'
  },
  {
    column: 'industry_codes',
    value: 'CODIGO_DA_INDUSTRIA'
  },
];

export async function SendExportCA (email: string, options: any, request: any) { 
  const filter: OrdersArgsFilterInput = {};
    
  options.forEach((filteredColumn: any) => {
    if (filteredColumn.id === 'begin_date') {
      filter.begin_date = format(new Date(filteredColumn.value), "yyyy-MM-dd")
    } else if (filteredColumn.id === 'end_date') {
      filter.end_date = format(new Date(filteredColumn.value), "yyyy-MM-dd")
    } else if (filteredColumn.id === 'currentPage') {
      options.page = filteredColumn.value;
    } else if (filteredColumn.id === 'client_identification') {
      filter.client_identification = filteredColumn.value;
    } else if (filteredColumn.id === 'status') {
      filter.status = filteredColumn.value;
    } else if (filteredColumn.id === 'id') {
      filter.id = Number(filteredColumn.value);
    }
  })

  options = filter;

  const columnHide = localStorage.getItem('CA-hidden-columns');
  const loadHiddenColumns = columnHide !== null
    ? JSON.parse(columnHide)
    : [];

  const columnsOptions = hookColumns.filter(objeto => !loadHiddenColumns.includes(objeto.column)).map(objeto => objeto.value);

  const params = {
    filter: options,
    send_to: email,
    columns_options: columnsOptions
  }

  const { exportGroupedOrders } = await request(
      gql`
      query exportGroupedOrders(
        $filter: GroupedOrderFilterType,
        $send_to: String!
        $columns_options: [ExportGroupedOrderColumnsEnum]
      ) {
          exportGroupedOrders(
            filter: $filter
            send_to: $send_to
            columns_options: $columns_options
          ) {
            status
            message
          }
      }`, params
  )

  return exportGroupedOrders
}