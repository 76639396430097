import { Grid } from "@mui/material";
import { 
  FilteredColumn, 
  GridView, 
} from "@common/components";
import { FilterTop, setDateFilter, ExportComponent } from "@shared/components";
import { Order } from "@ca/orders/contracts/Order";
import { useOrders } from "@ca/orders/hooks/useOrders";
import GridColumns from "@ca/orders/components/GridOrder/GridColumns";

const defaultFilterTop = {
  begin_date: new Date(),
  end_date: new Date(),
}

const defaultDatePicker: FilteredColumn[] = [
  {id: "begin_date", value: setDateFilter(defaultFilterTop.begin_date)},
  {id: "end_date", value: setDateFilter(defaultFilterTop.end_date, true)},
  {id: "status", value: ["AWAITING_PROCESSING", "AWAITING_ORDER_RESPONSE", "AWAITING_ORDER_INVOICE"]}
];

export default function GridOrder() {
  const columns = GridColumns();
  const RenderGridOrder = () => {
    return (
      <GridView<Order>
          columns={columns}
          hook={useOrders}
          defaultFilters={defaultDatePicker}
          paginated
          tableIdentifier="CA"
      />
    )
  }

  return (
    <>
      <FilterTop {...defaultFilterTop} />
      <Grid style={{marginTop: -70 + 'px'}}>
        <ExportComponent serviceType="CA" />
      </Grid>
      <RenderGridOrder />
    </>
  )
}
