import { NavBar } from "./NavBar";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "./ErrorFallback";
import { useLocation } from "react-router";

export function Layout({ children }: { children: any }) {
  const location = useLocation();

  return (
    <div className="flex h-full w-full">
      <NavBar />
      <div className="flex-1 h-full overflow-x-hidden overflow-y-auto p-8">
        <ErrorBoundary FallbackComponent={ErrorFallback} resetKeys={[location]}>
          {children}
        </ErrorBoundary>
      </div>
    </div>
  );
}
