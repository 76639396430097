import { TextField } from "@common/components/Form";
import { Autocomplete, Stack } from '@mui/material';
import useOrderStatus from '@we/orders/hooks/useOrderStatus';
import { useCallback, useState } from "react";

export function MultiSelectStatusFilterWE({column: { options, filterValue, setFilter, customInput },}: any) {
  const [defaultValueInput, setDefaultValueInput] = useState<any>(filterValue)

  const defaultOptions = filterValue.map((item: string) => {
    return options.filter((opt: any) => {
      return opt.value === item
    })[0]
  })

  const setValue = useCallback((value) => {
    setDefaultValueInput(value)
    setFilter(value)
  }, [])

  return (
    <Stack spacing={2} sx={{ width: 300 }}>
    <Autocomplete
      multiple
      freeSolo={customInput}
      limitTags={1}
      size="small"
      id="tags-standard"
      options={options}
      getOptionLabel={(option:any) => {
        if (typeof option === 'string') {
          option = options.filter((opt: any) => {
            return opt.value === option
          })[0]
        }

        return option.description
      }}
      value={defaultOptions}
      getOptionDisabled={(option) => {

        if (filterValue !== undefined) {
          const filterSelected = filterValue.find((value: any) => {
            return value === 'ORDER_NOT_IMPORTED';
          })

          if (filterSelected) {
            return option.value !== 'ORDER_NOT_IMPORTED'
          } 

          if (!filterSelected && filterValue.length > 0) {
            return option.value === 'ORDER_NOT_IMPORTED'
          }
        }

        return false
      }}
      onChange={(event, value)=> {
        const optValue = value.map((oValue) => {
          return oValue
        })

        let filters = customInput === undefined ? optValue.map(({value}) => value) : optValue;

        setValue(filters);
      }} 

      renderInput={(params) => (
        <TextField           
          {...params}
          value={filterValue || ""}
          variant="standard"
        />
      )}
    />
  </Stack>
  );
}
