import { PageInfo } from "@types";

export const PaginatorPage = ({ fromCursor, toCursor, total }: PageInfo) => {
  let showing = `${fromCursor}-${toCursor} de ${total} registros.`;

  if (total === 1) {
    showing = "Exibindo um registro.";
  }
  return <div className="text-gray-500 text-sm">{showing}</div>;
};
