import { IRoute, RenderRoutes } from "@src/routes";
import orderRoutes from "@ca/orders/routes";
import orderDetailsRoutes from "@ca/orderDetails/routes";

const mdRoutes: IRoute[] = [
  {
    menu: { label: "Monitoro Distribuidores" },
    path: "/ca",
    key: "MD_ROOT",
    component: (props: any) => <RenderRoutes {...props} />,
    routes: [
      ...orderRoutes,
      ...orderDetailsRoutes
    ],
  },
];

export default mdRoutes;
