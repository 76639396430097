export function ConvertIndustrySelectionOption(response: any) {
  if (response === undefined) {
    return [];
  }

  return response.map((industry: any) => {
    return {
      code: industry.code
    }
  })
}
