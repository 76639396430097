import { FormattedMessage } from "react-intl";

export function IntlEnum({
  name,
  value,
  params,
}: {
  name: string;
  value: string | undefined | null;
  params?: any;
}) {
  if (value === undefined || value === null) {
    return null;
  }
  const parsedValue = value.toLowerCase().replace(/[^a-z0-9_]/g, "");
  return <FormattedMessage id={`enums.${name}.${parsedValue}`} values={params} />;
}
