import { TextField } from "@common/components/Form";
import { Autocomplete, Stack } from '@mui/material';
import { useCallback } from "react";

export function MultiSelectIndustry({column: { options, filterValue, setFilter, customInput },}: any) {

  const setValue = useCallback((value) => {
    setFilter(value)
  }, [setFilter])

  return (
    <Stack spacing={2} sx={{ width: 150 }}>
      <Autocomplete
        multiple
        freeSolo={customInput}
        limitTags={1}
        size="small"
        id="tags-standard"
        options={options}
        getOptionLabel={(option:any) => {
          return option.code
        }}
        onChange={(event, value)=> {
          const filters = value.map((item) => {
            return item.code
          })
          
          setValue(filters);
        }} 
        renderInput={(params) => (
          <TextField           
            {...params}
            value={filterValue || ""}
            variant="standard"
          />
        )}
      />
  </Stack>
  );
}
