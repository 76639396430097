import { IntlEnum, Title } from '@common/components';
import GridOrder from '@we/orders/components/GridOrder/GridOrder';
import StatusTotal from '@we/orders/components/StatusTotal/StatusTotal';
import { GridViewProvider } from '@common/context/GridView/useGridView';

export default function ListOrder() {
    return (
        <>
            <GridViewProvider>
                <Title>
                    <IntlEnum name="orders" value="orders"/>
                </Title>
                <StatusTotal />
                <GridOrder />
            </GridViewProvider>
        </>
    )
}
