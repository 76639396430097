import { FormattedDate } from "react-intl";

export function IntlDateTime({
  value,
}: {
  value: string | Date | number | undefined;
}) {
  if (!value) {
    return null;
  }
  return (
    <span title={`${value}`}>
      <FormattedDate
        second="numeric"
        year="numeric"
        hour="numeric"
        minute="numeric"
        day="numeric"
        month="long"
        value={value}
      />
    </span>
  );
}
