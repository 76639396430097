import { useStoresGateway } from "@common/hooks/useStoresGateway";
import { OrderDetails } from "@ca/orderDetails/contracts/OrderDetails";
import { gql } from "graphql-request";
import { useQuery } from "react-query";
import { convertResponseCursorPaginated } from "@shared/helpers";
interface UseOrderDetailsProps {
  id?: number;
}

export function useOrderDetails(options: UseOrderDetailsProps) {

  const { request } = useStoresGateway();

  return useQuery("groupedOrder:" + JSON.stringify(options), async () => {
    const { groupedOrder } = await request(
      gql`
        query groupedOrder($id: Int!) {
          groupedOrder(id: $id) {
            id
            client_identification
            wholesaler
            created_at
            total_value
            status
            products {
              id
              ean
              product_reason
              industry_abbreviation
              industry_order_code
              order_discount
              ordered_quantity
            }
            invoices{
              danfe
              products{
                ean
                reimbursement_value
                invoice_quantity
                percent_discount
              }
            }
          }
        }
      `,
      options
    );

    const data: any = {
      data: [groupedOrder]
    }
    return convertResponseCursorPaginated<OrderDetails>(data);
  });  
}
