import { useMemo } from "react";

export function convertSelectionOption(registeredIndustries: any[]) {
  return registeredIndustries.map((status: any) => Object({value: status.code, description: status.name}));
}

export default function useOrderStatus(): any[] {
  return useMemo(
    () => [   
      {
        code: "ORDER_NOT_IMPORTED",
        name: "Pedido não importado"
      },
      {
        code: "AWAITING_RESPONSE",
        name: "Aguardando retorno do pedido"
      },
      {
        code: "AWAITING_INVOICE",
        name: "Aguardando a nota do pedido"
      }
    ], []);
}