import { format } from "date-fns";
import { gql } from "graphql-request";
import { FilteredColumn } from "@common/components";

export interface industryFilterType {
  code: [String]
}
export interface OrdersArgsFilterInput {
  begin_date?: string,
  end_date?: string,
  industries?: industryFilterType
}

interface UseOrdersOptions {
  id?: number;
  order_code?: number;
  customer_code?: string;
  wholesaler_branch_code?: string;
  status?: string | Array<string>;
  imported?: boolean;
  send_to?: string;
}

const hookColumns: { column: string, value: string }[] = [
  {
    column: 'order_code',
    value: 'ID_SUBPEDIDO'
  },
  {
    column: 'tradetools_created_at',
    value: 'DATA_DE_ENVIO'
  },
  {
    column: 'customer_code',
    value: 'CNPJ_PDV'
  },
  {
    column: 'wholesaler_branch_code',
    value: 'CNPJ_FILIAL_DISTRIBUIDOR'
  },
  {
    column: 'status',
    value: 'STATUS_DO_PEDIDO'
  },
  {
    column: 'industry_code',
    value: 'CODIGO_INDUSTRIA'
  },
];

export async function SendExportWE (email: string, options: any, request: any) { 
  const filter: OrdersArgsFilterInput = {};
  const optionsFilters: UseOrdersOptions = {};

  options.forEach((filteredColumn: FilteredColumn) => {
    if (filteredColumn.id === 'begin_date') {
      filter.begin_date = format(new Date(filteredColumn.value), "yyyy-MM-dd")
    } else if (filteredColumn.id === 'end_date') {
      filter.end_date = format(new Date(filteredColumn.value), "yyyy-MM-dd")
    } else if (filteredColumn.id === 'industry_code') {
      filter.industries = filteredColumn.value.map((item: string) => {
        return {
          code: item
        }
      })
    } else if (filteredColumn.id === 'id'){
      optionsFilters.id = filteredColumn.value;
      
    } else if (filteredColumn.id === 'order_code'){
      optionsFilters.order_code = filteredColumn.value;
    } else if (filteredColumn.id === 'customer_code'){
      optionsFilters.customer_code = filteredColumn.value;
    } else if (filteredColumn.id === 'wholesaler_branch_code'){
      optionsFilters.wholesaler_branch_code = filteredColumn.value;
    } else if (filteredColumn.id === 'status') {
      optionsFilters.status = filteredColumn.value;

      optionsFilters.imported = false;
      const isOrderNotImported = filteredColumn.value.find((value: any) => { return value === 'ORDER_NOT_IMPORTED'});

      if (filteredColumn.value.length > 0 && !isOrderNotImported) {
        optionsFilters.imported = true;
      }

      // set default filters
      if (filteredColumn.value.length <= 0) {
        optionsFilters.imported = true;
        optionsFilters.status = ['AWAITING_INVOICE', 'AWAITING_RESPONSE'];
      }

    }
  });

  const columnHide = localStorage.getItem('WE-hidden-columns');
  const loadHiddenColumns = columnHide !== null
    ? JSON.parse(columnHide)
    : [];

  const columnsOptions = hookColumns.filter(objeto => !loadHiddenColumns.includes(objeto.column)).map(objeto => objeto.value);

  const params = {
    filter: filter,
    send_to: email,
    status: optionsFilters.status,
    id: optionsFilters.id,
    order_code: optionsFilters.order_code,
    customer_code: optionsFilters.customer_code,
    wholesaler_branch_code: optionsFilters.wholesaler_branch_code,
    imported: optionsFilters.imported,
    columns_options: columnsOptions
  }

  const { exportOrders } = await request(
      gql`
      query exportOrders(
        $id: Int, 
        $order_code: Int,
        $customer_code: String,
        $wholesaler_branch_code: String,
        $imported: Boolean,
        $status: [OrderStatusEnum],
        $filter: OrderFilter,
        $send_to: String!,
        $columns_options: [OrderColumnsEnum]
      ) {
          exportOrders(
            filter: $filter
            id: $id, 
            order_code: $order_code,
            customer_code: $customer_code,
            wholesaler_branch_code: $wholesaler_branch_code,
            imported: $imported,
            send_to: $send_to
            status: $status,
            columns_options: $columns_options
          ) {
            status
            message
          }
      }`, params
  )

  return exportOrders
}