import { useTable, useExpanded, Column } from 'react-table'
import { Grid } from '@mui/material';
import { Table } from '@common/components/Table/Table';

interface GridViewProps {
  columns: Column<any>[];
  data: any,
  renderRowSubComponent?: Function
}

export function GridviewData<T>({
  columns,
  data,
  renderRowSubComponent,
}: GridViewProps) {
  const tableInstance = useTable(
    {
      columns,
      data: (data || []),
      renderRowSubComponent,
    } as any,
    useExpanded
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Table
          tableInstance={tableInstance}
          renderSubComponent={renderRowSubComponent}
        />
      </Grid>
    </Grid> 
  )
}
