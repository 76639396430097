import { Column } from "react-table";
import {
  IntlMoney,
  IntlPercentage,
  Typography,
  IntlEnum
} from "@common/components";
import { getValueEnumObject } from "@shared/helpers";
import { GridviewData } from "@shared/components";
import { Products } from "@ca/orderDetails/contracts/Products";
import { ProductStatus } from "@ca/orderDetails/types/ProductStatus";
import { EnumProductStatus } from "@ca/orderDetails/enums/EnumProductStatus";

interface GridViewProps<T> {
  data: T[],
}

const columnProducts: Column<Products>[] = [
  {
    Header: <IntlEnum name="orders_grid" value="industry_code" />,
    accessor: "industry_abbreviation"
  },
  {
    Header: <IntlEnum name="orders_grid" value="ean" />,
    accessor: "ean"
  },
  {
    Header: <IntlEnum name="orders_grid" value="industry_order_code" />,
    accessor: "industry_order_code"
  },
  {
    Header: <IntlEnum name="orders_grid" value="invoice_quantity" />,
    accessor: "invoice_quantity",
  },
  {
    Header: <IntlEnum name="orders_grid" value="percent_discount" />,
    accessor: "percent_discount",
    Cell: ({ value }) => <IntlPercentage value={value}/>,
  },
  {
    Header: <IntlEnum name="orders_grid" value="reimbursement_value" />,
    accessor: "reimbursement_value",
    Cell: ({ value }) => <IntlMoney value={value} />,
  },
  {
    Header: <IntlEnum name="orders_grid" value="danfe" />,
    accessor: "danfe"
  },
  {
    Header: <IntlEnum name="orders_grid" value="product_reason" />,
    accessor: "product_reason",
    Cell: ({ value }) => getValueEnumObject<ProductStatus>(EnumProductStatus, value.toString())
  },
];


export default function GridProducts<Products>({
  data
}: GridViewProps<Products>) {
  return (
    <>
      <Typography variant="h6" gutterBottom component="div">
        <IntlEnum name="orders" value="products"/>
      </Typography>
      <GridviewData<Products>
        columns={columnProducts}
        data={data}
      />
    </>
  )
}
