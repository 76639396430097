import { useAuth } from "react-oauth2-pkce";
import jwtDecode from "jwt-decode";

export class JwtUser {
  private ssoProfiles: { [key: string]: string[] } = {};
  private expiresAt: number;

  constructor(payload: any) {
    this.ssoProfiles = payload?.profiles || {};
    this.expiresAt = payload.exp;
  }

  expired() {
    return new Date().getTime() > this.expiresAt * 1000;
  }

  hasSsoRole(role: string) {
    return !!this.ssoProfiles[role];
  }

  hasSsoProfile(role: string, identity: string) {
    return this.getSsoRoleIdentities(role).includes(identity);
  }

  getSsoRoles() {
    return Object.keys(this.ssoProfiles);
  }

  getSsoRoleIdentities(role: string) {
    return this.ssoProfiles[role] || [];
  }

  hasAnyOfTheseSsoRoles(...roles: string[]) {
    if (!roles || roles.length === 0) {
      throw Error("No roles informed");
    }

    return this.getSsoRoles().filter((role) => roles.includes(role)).length > 0;
  }

  hasAllOfTheseSsoRoles(...roles: string[]) {
    if (!roles || roles.length === 0) {
      throw Error("No roles informed");
    }

    return (
      this.getSsoRoles().filter((role) => roles.includes(role)).length ===
      roles.length
    );
  }
}

export function jwtToUser(jwt: string | undefined) {
  if (jwt) {
    try {
      const payload = jwtDecode(jwt);
      return new JwtUser(payload);
    } catch (err) {
      console.error(err);
    }
  }
}

export function useJwtUser() {
  const { authService } = useAuth();

  const user = jwtToUser(authService.getAuthTokens()?.access_token);

  return {
    user,
    login: () => authService.authorize(),
    logout: () => authService.logout(),
    isPending: () => authService.isPending(),
    isAuthenticated: () => {
      if (!authService.isAuthenticated()) {
        return false;
      }

      if (user && user.expired()) {
        authService.logout();
        return false;
      }

      return true;
    },
  };
}
