import { IRoute, RenderRoutes } from "@src/routes";
import orderRoutes from "@we/orders/routes";
import orderDetailsRoutes from "@we/orderDetails/routes";

const mdRoutes: IRoute[] = [
  {
    menu: { label: "Monitoro Distribuidores" },
    path: "/we",
    key: "MD_ROOT",
    component: (props: any) => <RenderRoutes {...props} />,
    routes: [
      ...orderDetailsRoutes,
      ...orderRoutes
    ],
  },
];

export default mdRoutes;
