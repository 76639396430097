import { DatePicker as MuiDatePicker, DatePickerProps as MuiDatePickerProps } from "@mui/lab";
import { TextField } from "@common/components";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from "@mui/lab";
import { Control, Controller } from "react-hook-form";
import CalendarToday from "@mui/icons-material/CalendarToday";

export type NewMuiDatePickerProps = Omit<MuiDatePickerProps, "value" | "onChange" | "renderInput">;
export interface DatePickerProps extends NewMuiDatePickerProps {
  control?: Control<any>;
  controllerName?: string;
  errorMessage?: string;
}

export function DatePicker(props: DatePickerProps) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      locale={ptBR}
    >
      {
        props.control ? (
          <Controller
            control={props.control}
            name={props.controllerName as string}
            render={({ field }) => (
              <MuiDatePicker
                {...field}
                {...props}
                components={
                  {
                    OpenPickerIcon: () => <CalendarToday color={props.errorMessage ? "error" : "inherit"} fontSize="small"/>
                  }
                }
                renderInput={(propsTextField) => {
                  const {ref, ...propsText } = propsTextField;
                  return (
                    <TextField
                      size="small"
                      data-testid={props.controllerName}
                      errorMessage={props.errorMessage}
                      {...propsText}
                    />
                  )
                }}
              />
            )}
          />
        ) : null
      }
    </LocalizationProvider>
  )
};
