import OrdersDetails from "@we/orderDetails/pages/OrderDetails";
import { IRoute } from "@src/routes";

const routes: IRoute[] = [
  {
    path: "/we/OrderDetails/:order_code/:industry_code",
    key: "DETALHE_PEDIDO_WE",
    component: OrdersDetails,
  },
];

export default routes;