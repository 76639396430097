import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Column } from "react-table";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { 
  IntlDateTime, 
  IntlEnum, 
  ColumnFilterValidated, 
  ValidatedFilterProps,
} from "@common/components";
import { getValueEnumObject, ObjectEventEnum, CnpjValidation, ConvertIndustrySelectionOption } from "@shared/helpers";
import { Order } from "@ca/orders/contracts/Order";
import { EnumStatusOrder } from "@ca/orders/enums/EnumStatusOrder";
import useOrderStatus, { convertSelectionOption } from "@ca/orders/hooks/useOrderStatus";
import { StatusOrder } from "@ca/orders/types/StatusOrder";
import { MultiSelectStatusFilterCA } from "@ca/orders/components/MultiSelectStatusFilterCA/MultiSelectStatusFilterCA";
import { MultiSelectIndustryCA } from "@ca/orders/components/MultiSelectIndustryCA/MultiSelectIndustryCA";
import { Product } from "@ca/orders/contracts/Product";
import { useIndustry } from "@ca/orders/hooks/useIndustry";

export default function GridColumns() {
  const orderStatus = useOrderStatus();
  const industries = useIndustry();

  const cnpjFilterProps:ValidatedFilterProps = useMemo(() => {
    return {
      func: CnpjValidation,
      message: <IntlEnum name="cnpj_filter" value="string_range_validation" />,
      event: ObjectEventEnum["onBlur"],
      type: "number",
      useKey:[13],
    }
  }, [])

  const idValidation = ((label: JSX.Element): ValidatedFilterProps => {
    return {
      func: (value: any) => parseInt(value) <= 2147483647, 
      message: <IntlEnum name="integer_filter" value="integer_max_validation" params={{field: label}} />,
      event: ObjectEventEnum["onBlur"],
      type: "number",
      useKey:[13],
      maxValue: 10
    }
  });

  const columns: Column<Order>[] = useMemo(() => [
    {
      Header: <IntlEnum name="orders_grid" value="id" />,
      accessor: "id",
      Filter: ColumnFilterValidated,
      props: idValidation(<IntlEnum name="orders_grid" value="id" />),
      isVisible: false
    },
    {
      Header: <IntlEnum name="orders_grid" value="created_at" />,
      accessor: "created_at",
      Cell: ({ value }) => <IntlDateTime value={value} />,
      isVisible: false
    },
    {
      Header: <IntlEnum name="orders_grid" value="client_identification" />,
      accessor: "client_identification",
      Filter: ColumnFilterValidated,
      props: cnpjFilterProps,
    },
    {
      Header: <IntlEnum name="orders_grid" value="wholesaler_branch" />,
      accessor: "wholesaler_branch",
      Filter: ColumnFilterValidated,
      props: cnpjFilterProps,
      Cell: ({ value }) => value.code,
    },
    {
      Header: <IntlEnum name="orders_grid" value="client_code" />,
      accessor: "client_code",
      Filter: ColumnFilterValidated,
    },
    {
      Header: <IntlEnum name="orders_grid" value="status" />,
      accessor: "status",
      Cell: ({ value }) => getValueEnumObject<StatusOrder>(EnumStatusOrder, value),
      Filter: MultiSelectStatusFilterCA,
      options: convertSelectionOption(orderStatus),
    },
    {
      Header: <IntlEnum name="orders_grid" value="industry_order_code" />,
      accessor: "industry_codes",
      Filter: MultiSelectIndustryCA,
      options: ConvertIndustrySelectionOption(industries.data),
      Cell: ({ value }) => {
        const industry_codes = value.map((item: Product) => {
          return item.industry_abbreviation
        })

        const codes = industry_codes.filter(function(item, i) {
          return industry_codes.indexOf(item) === i;
        });

        return codes.join(', ')
      }
    },
    {
      Header: "",
      id: "icon",
      accessor: "id",
      width: '10px',
      Cell: ({ value }) => (
        <Link to={`/ca/orderDetails/${value}`}>
          <AddCircleIcon sx={{fill: "green"}}/>
        </Link>
      ),
    },
  ],[cnpjFilterProps, orderStatus, industries]);

  return columns;
}
