import { Control, Controller } from "react-hook-form";
import { v4 as uuid } from "uuid";
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from "@mui/material";

export interface TransformField {
  input: Function;
  output: Function;
}

export type TextFieldProps = MuiTextFieldProps & {
  control?: Control<any>;
  controllerName?: string;
  errorMessage?: string;
  transform?: TransformField;
};

export const TextField = (props: TextFieldProps) => {
  const injectedProps: MuiTextFieldProps = {
    id: props.id || props.name || props.controllerName || uuid(),
  };

  if (props.type === "date" || props.type === "datetime-local") {
    injectedProps.InputLabelProps = { shrink: true };

    if (props.type === "datetime-local") {
      injectedProps.inputProps = { step: 1 };
    }
  }

  if (props.type === "number") {
    injectedProps.InputProps = { inputMode: "decimal" };
  }

  const { errorMessage, controllerName, transform, ...transferedProps } = props;
  
  if (errorMessage) {
    injectedProps.error = true;
    injectedProps.helperText = errorMessage;
  }

  if (props.control) {
    return (
      <Controller
        name={(controllerName || props.name || props.id) as string}
        control={props.control}
        defaultValue={props.defaultValue || null}
        render={({ field }) => {
          if (transform) {
            injectedProps.onChange = (e) =>
              field.onChange(transform?.output(e));
            injectedProps.value = transform?.input(field.value);
          }

          return (
            <MuiTextField
              {...field}
              {...transferedProps}
              {...injectedProps}
              defaultValue={undefined}
              variant="outlined"
              fullWidth
            />
          );
        }}
      />
    );
  }
  return (
    <MuiTextField {...transferedProps} {...injectedProps} variant="outlined" fullWidth />
  );
};
