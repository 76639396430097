import { useCallback } from "react";
import { Column } from "react-table";
import {
  ApiLoadingSpinner,
  IntlEnum
} from "@common/components";
import { getValueEnumObject } from "@shared/helpers";
import { GridviewData } from "@shared/components";
import { useOrderDetails } from "@ca/orderDetails/hooks/useOrderDetails";
import { EnumStatusOrder } from "@ca/orders/enums/EnumStatusOrder";
import { StatusOrder } from "@ca/orders/types/StatusOrder";
import { OrderDetails } from "@ca/orderDetails/contracts/OrderDetails";
import GridProducts from "@ca/orderDetails/components/GridProducts/GridProducts";
import { Products } from "@ca/orderDetails/contracts/Products";
import { Invoices } from "@ca/orderDetails/contracts/Invoices";

interface ListOrderDetailsProps {
  id: number;
}

const columns: Column<OrderDetails>[] =  [
  {
    Header: <IntlEnum name="orders_grid" value="id" />,
    accessor: "id",
  },
  {
    Header: <IntlEnum name="orders_grid" value="client_identification" />,
    accessor: "client_identification",
  },
  {
    Header: <IntlEnum name="orders_grid" value="wholesaler" />,
    accessor: "wholesaler"
  },
  {
    Header: <IntlEnum name="orders_grid" value="created_at" />,
    accessor: "created_at",
  },
  {
    Header: <IntlEnum name="orders_grid" value="total_billed" />,
    accessor: "total_value",
  },
  {
    Header: <IntlEnum name="orders_grid" value="status" />,
    accessor: "status",
    Cell: ({ value }) => getValueEnumObject<StatusOrder>(EnumStatusOrder, value),
  },
];

export default function ListOrderDetails({id}: ListOrderDetailsProps) {
  const order = useOrderDetails({id});

  const renderRowSubComponent = useCallback(
    (row) => {
      const originalProducts = row.original.products;
      const products = originalProducts.map((product: Products) => {
        const invoices = row.original.invoices;
        return invoices.map((invoice: Invoices) => {
          const productInvoice = invoice.products.filter((productInvoice) => { return productInvoice.ean === product.ean })[0];
          return {
            id: product.id,
            ean: product.ean,
            product_reason: product.product_reason,
            industry_abbreviation: product.industry_abbreviation,
            industry_order_code: product.industry_order_code,
            danfe: invoice.danfe,
            order_discount: product.order_discount,
            ordered_quantity: product.ordered_quantity,
            reimbursement_value: productInvoice ? productInvoice.reimbursement_value : null,
            invoice_quantity: productInvoice ? productInvoice.invoice_quantity : null,
            percent_discount: productInvoice ? productInvoice.percent_discount : null,
          }
        })
      })[0];
      return (<GridProducts data={products} />)
    }, []
  );

  if (order.isLoading) {
    return (
      <div className="w-56 ml-2">
        <ApiLoadingSpinner />
      </div>
    );
  }
  
  return (
    <GridviewData<OrderDetails>
      columns={columns}
      data={order.data?.edges}
      renderRowSubComponent={(renderRowSubComponent)}
    />
  )
}
