import { createTheme } from "@mui/material/styles";


export const theme = createTheme({
  palette: {
    primary: {
      main: "#5b9911",
    },
    secondary: {
      main: "#519872",
    }
  },
  components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: "#f3f4f6",
          }
        },
      },
      MuiTableFooter: {
        styleOverrides:{
          root:{
            backgroundColor: "#f3f4f6",
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          body: {
            color: "#6b7280",
            "& + &:last-child *": { justifyContent: "flex-end" },
          },
          head: {
            color: "#6b7280",
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            verticalAlign: "top",
          },
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
          },
        }
      },
  }
});
