import { IntlEnum } from '@common/components';

export const EnumProductStatus = {
    AWAITING_PROCESSING: IntlEnum({ name: 'product_status', value: 'awaiting_processing' }),
    ORDER_CREATED_ON_INDUSTRY_OL_PORTAL: IntlEnum({ name: 'product_status', value: 'order_created_on_industry_ol_portal' }),
    NO_RESPONSE_FROM_INDUSTRY_OL_PORTAL: IntlEnum({ name: 'product_status', value: 'no_response_from_industry_ol_portal' }),
    PRODUCT_DOES_NOT_BELONG_TO_OL_FIDELIZE: IntlEnum({ name: 'product_status', value: 'product_does_not_belong_to_ol_fidelize' }),
    PRODUCT_DOES_NOT_BELONG_TO_SELECTED_INDUSTRY: IntlEnum({ name: 'product_status', value: 'product_does_not_belong_to_selected_industry' }),
    PRODUCT_NOT_RELEASED_ON_COMMERCIAL_CONDITION: IntlEnum({ name: 'product_status', value: 'product_not_released_on_commercial_condition' }),
    COMMERCIAL_POLICIES_NOT_AVAILABLE_FOR_ORDER_CONDITIONS: IntlEnum({ name: 'product_status', value: 'commercial_policies_not_available_for_order_conditions' }),
    COMMERCIAL_POLICIES_NOT_FOUND_FOR_PRODUCT_ON_INDUSTRY_OL_PORTAL: IntlEnum({ name: 'product_status', value: 'commercial_policies_not_found_for_product_on_industry_ol_portal' }),
    WHOLESALER_DISCOUNT_REQUIRED: IntlEnum({ name: 'product_status', value: 'wholesaler_discount_required' }),
    WHOLESALER_DISCOUNT_OUT_OF_RANGE: IntlEnum({ name: 'product_status', value: 'wholesaler_discount_out_of_range' }),
    PRODUCT_DISABLED_ON_INDUSTRY_OL_PORTAL: IntlEnum({ name: 'product_status', value: 'product_disabled_on_industry_ol_portal' }),
    CLIENT_NOT_FOUND_ON_INDUSTRY_OL_PORTAL: IntlEnum({ name: 'product_status', value: 'client_not_found_on_industry_ol_portal' }),
    WHOLESALER_BRANCH_NOT_AVAILABLE_FOR_ORDER_CONDITIONS: IntlEnum({ name: 'product_status', value: 'wholesaler_branch_not_available_for_order_conditions' }),
    WHOLESALER_BRANCH_NOT_ALLOWED_TO_INDUSTRY: IntlEnum({ name: 'product_status', value: 'wholesaler_branch_not_allowed_to_industry' }),
    PRODUCTS_NOT_AVAILABLES: IntlEnum({ name: 'product_status', value: 'products_not_availables' }),
    ERROR_ADDING_PRODUCT_TO_ORDER: IntlEnum({ name: 'product_status', value: 'error_adding_product_to_order' }),
    ERROR_SENDING_ORDER: IntlEnum({ name: 'product_status', value: 'error_sending_order' }),
    DISCOUNT_OUT_OF_PERMITED_RANGE: IntlEnum({ name: 'product_status', value: 'discount_out_of_permitted_range' }),
    DUPLICATE_ORDER_ON_INDUSTRY_OL_PORTAL: IntlEnum({ name: 'product_status', value: 'duplicate_order_on_industry_ol_portal' })
} as const;
  