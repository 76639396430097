import { BreadCrumb } from "@common/components";
import ListOrderDetails from "@ca/orderDetails/components/ListOrderDetails/ListOrderDetails";
import { useParams } from "react-router-dom";

interface OrdersDetailsParams {
  id: string;
}

export default function OrdersDetails() {

  let {id}  = useParams<OrdersDetailsParams>()

  return (
    <>
      <BreadCrumb items={ [ { path: "/ca/orders", label: "Pedidos" }, { label: "Detalhes do Pedido"} ] }/>
      <ListOrderDetails id={parseInt(id)} />
    </>
  );
}
