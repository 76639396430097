import { IntlEnum } from '@common/components';

export const EnumStatusOrder = {
    ORDER_NOT_IMPORTED: IntlEnum({ name: 'we_status_order', value: 'order_not_imported' }),
    AWAITING_RESPONSE: IntlEnum({ name: 'we_status_order', value: 'awating_response' }),
    AWAITING_INVOICE: IntlEnum({ name: 'we_status_order', value: 'awating_invoice' }),
    INVOICE_RECEIVED: IntlEnum({ name: 'we_status_order', value: 'invoice_received' }),
    CANCELLED: IntlEnum({ name: 'we_status_order', value: 'order_cancelled' }),
    REJECTED: IntlEnum({ name: 'we_status_order', value: 'order_rejected' }),
} as const;
  