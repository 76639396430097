import { IntlEnum } from '@common/components';

export const EnumStatusOrder = {
    AWAITING_PROCESSING: IntlEnum({ name: 'status_order', value: 'awaiting_processing' }),
    PROCESSING: IntlEnum({ name: 'status_order', value: 'processing' }),
    PROCESSED: IntlEnum({ name: 'status_order', value: 'processed' }),
    AWAITING_ORDER_RESPONSE: IntlEnum({ name: 'status_order', value: 'awaiting_order_response' }),
    AWAITING_ORDER_INVOICE: IntlEnum({ name: 'status_order', value: 'awaiting_order_invoice' }),
    INVOICE_RECEIVED: IntlEnum({ name: 'status_order', value: 'invoice_received' }),
    AWAITING_CANCELLATION_PROCESSING: IntlEnum({ name: 'status_order', value: 'awaiting_cancellation_processing' }),
    PROCESSING_CANCELLATION: IntlEnum({ name: 'status_order', value: 'processing_cancellation' }),
    CANCELLED: IntlEnum({ name: 'status_order', value: 'cancelled' })
} as const;
  