import { Link as RouterLink } from "react-router-dom";

export function Link(props: any) {
  const innerHtml = props.label || props.children;
  const classNames =
    "cursor-pointer text-brand hover:underline active:text-green-900 hover:text-green-900";
  if (props.onClick) {
    return (
      <span {...props} className={classNames}>
        {innerHtml}
      </span>
    );
  }
  return (
    <RouterLink {...props} className={classNames}>
      {innerHtml}
    </RouterLink>
  );
}
