import { FilteredColumn } from "@common/components";
import { createContext, ReactNode, useContext, useState } from "react";

export interface GridViewContextType{
  gridFilters: FilteredColumn[],
  setGridFilters: (value: FilteredColumn[]) => void,
  items: any,
  setItems: (value: any) => void,
  disableExportButton: boolean,
  setDisableExportButton: (value: any) => void,
  setTableFilters: (value: FilteredColumn[]) => void,
  tableFilters: FilteredColumn[],
  openDialogColumns: boolean,
  setOpenDialogColumns: (value: boolean) => void
}

export const GridViewContext = createContext<GridViewContextType>({} as GridViewContextType);
GridViewContext.displayName = "GridView";

export interface GridViewProviderProps {
  children: ReactNode
}

export function GridViewProvider({ children }: GridViewProviderProps) {
  const [gridFilters, setGridFilters] = useState<FilteredColumn[]>([]);
  const [items, setItems] = useState<any>([]);
  const [disableExportButton, setDisableExportButton] = useState<any>(true);
  const [tableFilters, setTableFilters] = useState<any>([]);
  const [openDialogColumns, setOpenDialogColumns] = useState(false);

  return(
    <GridViewContext.Provider value={{
      gridFilters,
      setGridFilters,
      items,
      setItems,
      disableExportButton,
      setDisableExportButton,
      setTableFilters,
      tableFilters,
      openDialogColumns,
      setOpenDialogColumns,
    }}>
      {children}
    </GridViewContext.Provider>
  );
}

export function useGridView() {
  const {gridFilters, setGridFilters, items, setItems} = useContext(GridViewContext);
  return {gridFilters, setGridFilters, items, setItems};
}

export function useExportButton() {
  const {disableExportButton, setDisableExportButton} = useContext(GridViewContext);
  return {disableExportButton, setDisableExportButton};
}

export function useTableFilters() {
  const {tableFilters, setTableFilters} = useContext(GridViewContext);
  return {tableFilters, setTableFilters};
}

export function useOpenDialogColumns() {
  const { openDialogColumns, setOpenDialogColumns } = useContext(GridViewContext);
  return {openDialogColumns, setOpenDialogColumns};
}
