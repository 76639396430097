export function InlineSpinner({ children }: { children: any }) {
  return (
    <div className="relative">
      <div className="inline-spinner">&nbsp;</div>
      {children ? (
        <div className="ml-4 align-bottom inline-block leading-8">
          {children}
        </div>
      ) : null}
    </div>
  );
}
