import { Redirect } from "react-router";
import { useState } from "react";
import { Button, InlineSpinner, Link } from "@common/components";
import { useJwtUser } from "@common/hooks";
import logo from "@assets/images/funcional.svg";

const ssoUrl =
  process?.env?.REACT_APP_SSO_URL || window?.environment?.REACT_APP_SSO_URL;

export default function LoginPage() {
  const { isPending, isAuthenticated, login, logout } = useJwtUser();
  const [loginButtonDisabled, setLoginButtonDisabled] = useState(false);

  if (isPending()) {
    let message = "Finalizando autenticação...";

    if (["", "__REACT_APP_SSO_URL__"].includes(ssoUrl)) {
      message =
        "A autenticação não foi configurada. Entre em contato com o administrador do site.";
    }

    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 text-center">
          <InlineSpinner>{message}</InlineSpinner>

          <div className="mt-4 mx-auto text-center text-sm text-gray-600">
            Está demorando muito? <Link onClick={logout}>Tentar novamente</Link>
            .
          </div>
        </div>
      </div>
    );
  }

  if (isAuthenticated()) {
    return <Redirect to="/" />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8 shadow rounded-md bg-white px-4 py-5 sm:p-6">
        <div>
          <div className="text-center">
            <img
              src={logo}
              alt="Funcional"
              className="max-w-full w-48 mx-auto"
            />
          </div>

          <h2 className="mt-8 text-center text-xl font-medium leading-6 text-gray-800">
            Console de Gerenciamento
          </h2>
          <p className="mt-6 text-center text-sm text-gray-600">
            Tudo o que você precisa para acessar e gerenciar os programas de
            descontos e ferramentas da Funcional, em uma única interface web
          </p>
        </div>
        <div className="mt-8 space-y-6">
          <div>
            <Button
              color="primary"
              className="w-full text-center"
              disabled={loginButtonDisabled}
              onClick={() => {
                setLoginButtonDisabled(true);
                login();
              }}
            >
              Acessar com o Single Sign On
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
