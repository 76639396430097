import { FormattedNumber } from "react-intl";

export function IntlMoney({
  value,
  currency = "BRL",
}: {
  value: number | bigint | undefined;
  currency?: string;
}) {
  if (value === undefined || value === null) {
    return null;
  }
  // eslint-disable-next-line react/style-prop-object
  return <FormattedNumber value={value} style="currency" currency={currency} />;
}
